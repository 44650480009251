<template>
    <div>
        <uniqueTournamentList :sport_id="sport_id"></uniqueTournamentList>
    </div>
</template>

<script>

import uniqueTournamentList from '@/components/ballsports/unique_tournament/list.vue';

export default {
    components: {
        uniqueTournamentList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 8,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>