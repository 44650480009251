<template>
  <div>
    <Modal
        v-model="modal_add_relation"
        :title="'联赛id: ' + new_relation.id"
        @on-ok="ok"
    >
        <thirdUniqueTournamentSearch :sport_id="sport_id" v-model="new_relation.third" @onSourceIdChange="newRelationSourceIdChange" ></thirdUniqueTournamentSearch>
    </Modal>

    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="false"
    >
        <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="0" @closePage="closeEditPage"></uniqueTournamentEdit>
    </Drawer>  

    <Modal v-model="modal_edit_unique_tournament" draggable title="编辑赛事" footer-hide >
        <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
    </Modal>

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="5" style="text-align: left">
            <Select v-model="category_id" @on-change='onCategoryIdChange' >
                <Option :value="0" :key="0">全部</Option>
                <Option v-for="item in cayegory_dic" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
        </Col>
        <Col span="15" style="text-align: right">
            <!-- 跳转到编辑页面 -->
            <Button type="success"  @click="drawer_edit=true">新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getUniqueTournament, getUniqueTournamentCategory, 
        addUniqueTournamentRelation, delUniqueTournamentRelation } 
    from '@/api/ballsports/base/unique_tournament';
import thirdUniqueTournamentSearch from './third_source_search.vue';
import uniqueTournamentEdit from './edit.vue';
import {getImgUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, unique_tournament_id: params.row.id }
                    // vm.$router.push({path: '/numer', query: query});
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/ballsports/'+ sport.name_en +'/unique_tournament/detail';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型")
                    }
                    
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "unique-tournament-list",
    props: {
        sport_id: Number,   // 运动id
    },
    components: {
        thirdUniqueTournamentSearch,
        uniqueTournamentEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            category_id: 0,
            cayegory_dic: {},
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 100,
                },
                
                {
                    title: '名称',
                    align: 'center',
                    key: 'name_lang',
                    minWidth: 80,
                },
                {
                    title: '简称',
                    align: 'center',
                    key: 'short_name',
                    minWidth: 80,
                },
                {
                    title: 'logo',
                    align: 'center',
                    key: 'logo',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        return  h("Row", { attrs: {justify: 'center' }}, [h('img', { attrs: { src:  getImgUrl(self.sport_id, 'unique-tournament', currentRow.logo) },
                                            class: {'unique-tournament-logo': true} })],)
                    }
                },
                {
                    title: '分类',
                    align: 'center',
                    key: 'category',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let category_id = currentRow.category_id 
                        let category = self.cayegory_dic[category_id] || {};
                        return h('span', category.name || '')
                    }
                },
                {
                    title: '父级赛事',
                    align: 'center',
                    key: 'parent',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let parent = currentRow.parent || {};
                        if (parent && parent.id > 0){
                            var _texts =  [ 
                                // h("Row", {attrs: {justify: 'center' }}, [
                                //     h('router-link',
                                //         {
                                //             attrs:{
                                //                 target: "_blank", 
                                //             },
                                //             props: {
                                //                 to: {
                                //                     path: '/ballsports/base/unique_tournament/detail',
                                //                     query: {
                                //                         sport_id: currentRow.sport_id,
                                //                         unique_tournament_id:  parent.id
                                //                     }
                                //                 },
                                //             }
                                //         },
                                //         parent.id
                                //     ),
                                // ]),
                                // h("Row", {attrs: {justify: 'center' }}, [h('span', { attrs: { class: 'text-assist'  }}, parent.name_lang ),]),
                                h('span', {class: 'text-link', on: {
                                    'click':() => {
                                        self.editUniqueTournament(parent.id);
                                    }
                                } },  parent.id + ' ',  ),
                                h('span', {class: 'text-main',on: {
                                    'click':() => {
                                        self.editUniqueTournament(parent.id);
                                    }
                                } }, parent.name_lang, ),
                            ]
                        }else{
                            var _texts = [h('span', '')];
                        }
                        return _texts
                    }
                },
                {
                    title: '国家',
                    align: 'center',
                    key: 'country',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var country = currentRow.country || {}
                        return h('span', country.name)
                    }
                },
                // {
                //     title: '关联',
                //     align: 'left',
                //     key: 'relation',
                //     minWidth: 120,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let relation = currentRow.relation || {};
                //         if (relation && relation.length > 0){
                //             var _texts =  [ 
                //                 // h("Row", {attrs: {justify: 'center' }}, [h('span', { attrs: {  }}, "ID: " + parent.id ),]),
                //                 // h("Row", {attrs: {justify: 'center' }}, [h('span', { attrs: { class: 'text-assist'  }}, parent.name ),]),
                //             ]
                //             relation.forEach((element, _index) => {
                //                 let source_name = element.source_name;
                //                 _texts.push( 
                //                     h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Poptip', {
                //                                     props: {
                //                                         placement: 'left-start',
                //                                         confirm: true,
                //                                         transfer: true,
                //                                         title: '确定删除"'+ source_name +'"吗？',
                //                                     },
                //                                     on: {
                //                                         'on-ok': () => {
                //                                             // this.deletedMind();//调用删除方法
                //                                             self.delRelation( currentRow.id , element.source_id, element.third_id);
                //                                             relation.splice(_index, 1);
                //                                         },
                //                                         'on-cancel': () => {
                //                                         }
                //                                     }
                //                                 }, 
                //                                 [h('Icon', 
                //                                     {   
                //                                         attrs: { type: "md-trash", }, 
                //                                         // on: {
                //                                         //     'click':() => {
                //                                         //         relation.splice(_index, 1);
                //                                         //     }
                //                                         // } 
                //                                     },  
                //                                 )] 
                //                             )],
                //                         ),
                //                         h('Col', { attrs: { span: 5 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-main", 
                //                                 }, 
                //                                 element.source_name + ':',
                //                             )]
                //                         ),
                //                         h('Col', { attrs: { span: 6 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-link", 
                //                                 }, 
                //                                 element.third_id,
                //                             )] 
                //                         ),
                //                         // h('Col', { attrs: { span: 10 }}, 
                //                         //     [h('span', 
                //                         //         {   
                //                         //             class: "text-assist", 
                //                         //         }, 
                //                         //         element.third_name,
                //                         //     )] 
                //                         // ),
                //                         ]
                //                     )
                //                 )
                //             });
                //         }else{
                //             var _texts = [];
                //         }
                //         _texts.push( h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Icon', 
                //                                 {   
                //                                     attrs: { type: "md-add-circle", }, 
                //                                     on: {
                //                                         'click':() => {
                //                                             // 为当前联赛新增关联
                //                                             self.add(currentRow.id);
                //                                             self.edited_relation = relation;
                //                                         }
                //                                     } 
                //                                 },  
                //                             )] 
                //                         ),
                //                         ]
                //                     ) )
                //         return _texts
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        if (self.sport_id == 1 || self.sport_id == 2){
                            return []
                        }
                        return [ editButton(self, h, params), ]// delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            new_relation: {
                id: 0, // 比赛id
                source_id: '',  // 源id
                third: {
                    id: 0,      // 赛事id
                    name: '',   // 赛事名称
                }
            },
            edited_relation: [],
            modal_edit_unique_tournament: false,
            edited_unique_tournament_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onCategoryIdChange(value){
            let self = this;
            self.getData();
        },
        // 获取联赛分类
        getCategorys(){
            let lang = sessionStorage.getItem("lang");
            var params = {
                sport_id: this.sport_id,
                lang: lang,
            }
            getUniqueTournamentCategory(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.cayegory_dic = response.data.data;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getData () {
            this.loading = true;
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            var params = {
                sport_id: this.sport_id,
                search_key: this.search_key,
                page: this.current_page,
                category_id: this.category_id,
                lang: lang,
            }
            getUniqueTournament(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            addUniqueTournamentRelation(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('添加成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            delUniqueTournamentRelation(params).then(response => {
                if (response.data.code == 0) {
                    // self.$Message.success(response.data.msg);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add ( _id ) {
            this.new_relation["id"] = _id;
            this.modal_add_relation = true;
        },
        // end
        newRelationSourceIdChange(source_id, source_name){
            this.new_relation.source_id = source_id;
            this.new_relation.source_name = source_name;
        },
        ok () {
            // 为联赛新增加第三方的关联
            let self = this;
            if (self.new_relation && self.new_relation.third){
                self.addRelation(self.new_relation.id, self.new_relation.source_id, self.new_relation.third.id)
                self.edited_relation.push(
                    {
                        source_id: self.new_relation.source_id,
                        source_name: self.new_relation.source_name,
                        third_id: self.new_relation.third.id,
                        third_name: self.new_relation.third.name,
                    }
                )
            }
        },
        // delete (_id) {
        //     var params = {
        //         id: _id,
        //         type: this.data_type
        //     }
        //     deleteData(params).then(response => {
        //         if (response.data.code == 0) {
        //             return true
        //         } else {
        //             this.$Message.error(response.data.msg)
        //             return false
        //         }
        //     })
        // },
        // end 
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end 
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end
    },
    mounted () {
        this.getCategorys();
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css"
</style>